<template>
<div>


    <div class="filters-bar">
        <h2>Filter by</h2>
        <div class="filters-row">
 
            <div class="ricerca-testo">
                <label for="searchyear">Started in</label>
                <select name="searchyear" id="" @change="filterbyyear()">
                    <option value="all">All years</option>
                    <option v-for="y in this.allyears" :key="'year_'+y" :value="y">{{ y }}</option>
                </select>
            </div>

            <div class="ricerca-testo">
                <label for="searchyear">Current/Finished</label>
                <select name="searchyear" id="" @change="filterbyfinishedyear()">
                    <option value="all">All</option>
                    <option v-for="y in this.finishedYears" :key="'year_'+y" :value="y">{{ y }}</option>
                </select>
            </div>

            <div class="ricerca-testo">
                <label for="searchauthor">Supervisor name</label>
                <input v-model="searchauthor" name="searchauthor" placeholder="Search...">
            </div> 

        </div>

        <div class="sorting">
            <div>Sort by</div>
            <input type="radio" name="sorting" id="sortdate" value="date" checked="checked" @click="radioclick()">
            <label for="sortdate">Date</label>
            
            <input type="radio" name="sorting" id="sorttitle" value="title" @click="radioclick()">
            <label for="sorttitle">Title</label>
        </div>
    </div> 



    <div class="publication-list thesis">


        <!-- plurale "s" nel titolo solo in pagina students porjects -->
        <h2
        v-if="
            this.$route.path.includes('/person/') && 
            this.loadedThesis_phd.length != 0 || 
            !this.$route.path.includes('/person/')
            && this.yearfinished != 'Finished'
        "
        >PhD Project<span v-if="
            !this.$route.path.includes('/person/')
        ">s</span></h2>


        <Thesis :data="loadedThesis_phd" v-if="this.yearfinished != 'Finished'"/>

        <p v-if="
            this.filteredThesis.length==0 &&  !this.$route.path.includes('/person/') ||
            loadedThesis_phd.length==0 &&  !this.$route.path.includes('/person/')
            && this.yearfinished != 'Finished'
        ">No project found</p>


        <!-- plurale "s" nel titolo solo in pagina students porjects -->

        <h2 
        v-if="
            this.$route.path.includes('/person/') && 
            this.loadedThesis_masterProj.length != 0 || 
            !this.$route.path.includes('/person/')
            && this.yearfinished != 'Finished'
        "
        >Master Project<span v-if="
            !this.$route.path.includes('/person/')
        ">s</span></h2>



        <Thesis :data="loadedThesis_masterProj" v-if="this.yearfinished != 'Finished'"/>

        <p v-if="
            this.filteredThesis.length==0 &&  !this.$route.path.includes('/person/') ||
            loadedThesis_masterProj.length==0 &&  !this.$route.path.includes('/person/')
            && this.yearfinished != 'Finished'
        ">No project found</p>


        <!-- past thesis -->
        <h2 
        v-if="
            this.$route.path.includes('/person/') && 
            this.pastThesis.length != 0 || 
            !this.$route.path.includes('/person/')
            && this.yearfinished != 'Current'
        "
        >Past Project<span v-if="
            !this.$route.path.includes('/person/')
        ">s</span></h2>

        <Thesis :data="pastThesis" v-if="this.yearfinished != 'Current'" />

        <p v-if="
            this.pastThesis.length==0 &&  !this.$route.path.includes('/person/')
            && this.yearfinished != 'Current'
        ">No project found</p>


    </div>


</div>
</template>

<script>
import Thesis from '../components/Thesis.vue';


export default {
    name: 'thesis-list',
    components: { Thesis },
    props: {
        thesisData: Array,
     },
    data: () => {
        return {
            loaded: 10,
            searchtitle: '',
            searchauthor: '',
            sorting: 'date',
            yearfilter: 'all',
            yearfinished: 'all',
            typefilter: 'all',
            studentPic:[]
        }
    },
    methods: {
        radioclick() {
            this.sorting = event.target.value
        },
        printAuthors(thesis) {
            if(thesis.field_publication_authors) {
                var auths = thesis.field_publication_authors.split(" | ")
                if(auths.length > 6) {
                    auths = auths.slice(0, 6)
                    auths.push('... and others')
                }
                return auths
            }
            else return []
        },
   
        
      
        
       
        
        loadMore() {
            this.loaded += 10
        },
        filterbyyear() {
            this.yearfilter = event.target.value
        },
        filterbyfinishedyear() {
            this.yearfinished = event.target.value
        },
        filterbytype() {
            this.typefilter = event.target.value
        },
        
        openaccordion() {
            event.target.closest('.pub-authors').classList.toggle('open')
        }
    },
    computed: {
        
        loadedThesis_masterProj() {
            let data = this.filteredThesis.filter(w => w.attributes.field_type == "Master Projects")
            return data
        },
        loadedThesis_phd() {
            let data = this.filteredThesis.filter(w => w.attributes.field_type == "PhD Projects")
            return data
        },
        pastThesis() {
            let data = this.thesisData.filter(w => w.attributes.field_finishing_year_ < new Date().getFullYear().toString())

            if(this.yearfilter != "all") {
                data = data.filter(w => w.attributes.field_starting_year == this.yearfilter)
            }

            /* SEARCH BY AUTHOR */
            if(this.searchauthor) {
                data = data.filter(w => {
                    const searchTerm = this.searchauthor.toLowerCase();

                    const internalSupervisorMatch = w.field_internal_supervisor.some(supervisor =>
                        supervisor.attributes.title.toLowerCase().includes(searchTerm)
                    );

                    const externalSupervisorMatch = w.attributes.field_external_supervisor.some(external =>
                        external.toLowerCase().includes(searchTerm)
                    );

                    return internalSupervisorMatch || externalSupervisorMatch;
                });
            }

            /* SORTING */
            if(this.sorting == 'date')
                data.sort((a, b) => {
                    return a.attributes.field_starting_year > b.attributes.field_starting_year ? -1 : 1
                })
            else if(this.sorting == 'title')
                data.sort((a, b) => {
                    return a.attributes.title > b.attributes.title ? 1 : -1
                })

            return data
        },
        filteredThesis() {
            var b = []


            b = this.thesisData.filter(w => !Array.isArray(w))
      

            /* FILTER BY YEAR */
            if(this.yearfilter != "all") {
                b = b.filter(w => w.attributes.field_starting_year == this.yearfilter)
            }

            /* FILTER BY CURRENT/FINISHED */

            if(this.yearfinished != "Finished"){
                b = b.filter(w =>  w.attributes.field_finishing_year_ != null &&  w.attributes.field_finishing_year_ >= new Date().getFullYear().toString()) 
            }


            /* if(this.yearfinished == "Finished"){
                b = b.filter(w =>  w.attributes.field_finishing_year_ != null &&  w.attributes.field_finishing_year_ < new Date().getFullYear().toString()) 

            } else if(this.yearfinished ==  "Current"){
                b = b.filter(w => w.attributes.field_finishing_year_ == null || w.attributes.field_finishing_year_ >= new Date().getFullYear().toString()  )

            } */

            // /* FILTER BY TYPE */
            // if(this.typefilter != "all") {
            //     b = b.filter(w => w.field_publication_type == this.typefilter)
            // }

            // /* SEARCH BY TITLE */
            // if(this.searchtitle) {
            //     b = b.filter(w => w.title.toLowerCase().includes(this.searchtitle.toLowerCase()))
            // }


            /* SEARCH BY AUTHOR */
            if(this.searchauthor) {
                b = b.filter(w => {
                    const searchTerm = this.searchauthor.toLowerCase();

                    const internalSupervisorMatch = w.field_internal_supervisor.some(supervisor =>
                        supervisor.attributes.title.toLowerCase().includes(searchTerm)
                    );

                    const externalSupervisorMatch = w.attributes.field_external_supervisor.some(external =>
                        external.toLowerCase().includes(searchTerm)
                    );

                    return internalSupervisorMatch || externalSupervisorMatch;
                });
            }



            /* SORTING */
            if(this.sorting == 'date')
                b.sort((a, b) => {
                    return a.attributes.field_starting_year > b.attributes.field_starting_year ? -1 : 1
                })
            else if(this.sorting == 'title')
                b.sort((a, b) => {
                    console.log('A',a);
                    return a.attributes.title > b.attributes.title ? 1 : -1
                })

            return b


        },
        allyears() {
            var yy = []

            this.thesisData.forEach(y => {
                if(!yy.includes(y.attributes.field_starting_year) && y.attributes.field_starting_year!="")
                    yy.push(y.attributes.field_starting_year)
            })

            yy.sort((a, b) => parseInt(a) - parseInt(b));

            return yy
        },
        finishedYears() {
            var yy = ["Current","Finished"]
            return yy
        }
    },
    mounted(){
     }
}
</script>

<style lang="scss" scoped>
</style>