var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"filters-bar"},[_c('h2',[_vm._v("Filter by")]),_c('div',{staticClass:"filters-row"},[_c('div',{staticClass:"ricerca-testo"},[_c('label',{attrs:{"for":"searchyear"}},[_vm._v("Started in")]),_c('select',{attrs:{"name":"searchyear","id":""},on:{"change":function($event){return _vm.filterbyyear()}}},[_c('option',{attrs:{"value":"all"}},[_vm._v("All years")]),_vm._l((this.allyears),function(y){return _c('option',{key:'year_'+y,domProps:{"value":y}},[_vm._v(_vm._s(y))])})],2)]),_c('div',{staticClass:"ricerca-testo"},[_c('label',{attrs:{"for":"searchyear"}},[_vm._v("Current/Finished")]),_c('select',{attrs:{"name":"searchyear","id":""},on:{"change":function($event){return _vm.filterbyfinishedyear()}}},[_c('option',{attrs:{"value":"all"}},[_vm._v("All")]),_vm._l((this.finishedYears),function(y){return _c('option',{key:'year_'+y,domProps:{"value":y}},[_vm._v(_vm._s(y))])})],2)]),_c('div',{staticClass:"ricerca-testo"},[_c('label',{attrs:{"for":"searchauthor"}},[_vm._v("Supervisor name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchauthor),expression:"searchauthor"}],attrs:{"name":"searchauthor","placeholder":"Search..."},domProps:{"value":(_vm.searchauthor)},on:{"input":function($event){if($event.target.composing){ return; }_vm.searchauthor=$event.target.value}}})])]),_c('div',{staticClass:"sorting"},[_c('div',[_vm._v("Sort by")]),_c('input',{attrs:{"type":"radio","name":"sorting","id":"sortdate","value":"date","checked":"checked"},on:{"click":function($event){return _vm.radioclick()}}}),_c('label',{attrs:{"for":"sortdate"}},[_vm._v("Date")]),_c('input',{attrs:{"type":"radio","name":"sorting","id":"sorttitle","value":"title"},on:{"click":function($event){return _vm.radioclick()}}}),_c('label',{attrs:{"for":"sorttitle"}},[_vm._v("Title")])])]),_c('div',{staticClass:"publication-list thesis"},[(
            this.$route.path.includes('/person/') && 
            this.loadedThesis_phd.length != 0 || 
            !this.$route.path.includes('/person/')
            && this.yearfinished != 'Finished'
        )?_c('h2',[_vm._v("PhD Project"),(
            !this.$route.path.includes('/person/')
        )?_c('span',[_vm._v("s")]):_vm._e()]):_vm._e(),(this.yearfinished != 'Finished')?_c('Thesis',{attrs:{"data":_vm.loadedThesis_phd}}):_vm._e(),(
            this.filteredThesis.length==0 &&  !this.$route.path.includes('/person/') ||
            _vm.loadedThesis_phd.length==0 &&  !this.$route.path.includes('/person/')
            && this.yearfinished != 'Finished'
        )?_c('p',[_vm._v("No project found")]):_vm._e(),(
            this.$route.path.includes('/person/') && 
            this.loadedThesis_masterProj.length != 0 || 
            !this.$route.path.includes('/person/')
            && this.yearfinished != 'Finished'
        )?_c('h2',[_vm._v("Master Project"),(
            !this.$route.path.includes('/person/')
        )?_c('span',[_vm._v("s")]):_vm._e()]):_vm._e(),(this.yearfinished != 'Finished')?_c('Thesis',{attrs:{"data":_vm.loadedThesis_masterProj}}):_vm._e(),(
            this.filteredThesis.length==0 &&  !this.$route.path.includes('/person/') ||
            _vm.loadedThesis_masterProj.length==0 &&  !this.$route.path.includes('/person/')
            && this.yearfinished != 'Finished'
        )?_c('p',[_vm._v("No project found")]):_vm._e(),(
            this.$route.path.includes('/person/') && 
            this.pastThesis.length != 0 || 
            !this.$route.path.includes('/person/')
            && this.yearfinished != 'Current'
        )?_c('h2',[_vm._v("Past Project"),(
            !this.$route.path.includes('/person/')
        )?_c('span',[_vm._v("s")]):_vm._e()]):_vm._e(),(this.yearfinished != 'Current')?_c('Thesis',{attrs:{"data":_vm.pastThesis}}):_vm._e(),(
            this.pastThesis.length==0 &&  !this.$route.path.includes('/person/')
            && this.yearfinished != 'Current'
        )?_c('p',[_vm._v("No project found")]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }